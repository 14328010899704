import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { LayoutContext } from '@/Layouts/layout/context/layoutcontext.jsx'
import { Toast } from 'primereact/toast'
import { useCookies } from 'react-cookie'

const Context = createContext()

const navigator_language = navigator.language.split('-')[0]
const fauna_lang = document.cookie.split('; ').find((row) => row.startsWith('fauna_lang='))?.split('=')[1]
const default_language = 'en' //fauna_lang || navigator_language

let storedMessages = localStorage.getItem('messages_' + default_language) ? JSON.parse(localStorage.getItem('messages_' + default_language)) : { lang_md5: '' }

const langs = {
    da: 'Dansk',
    en: 'English',
}

const IntlProviderWrapper = ({ children, lang_md5 }) => {
    const toast = useRef(null)
    const [cookies, setCookie] = useCookies([])
    const [locale, setLocale] = useState(default_language)
    const [messages, setMessages] = useState()
    const { setLanguageLoading, showLanguageDialog } = useContext(LayoutContext)

    async function getLang(locale, showToast = false) {
        await axios.get('/lang/' + locale)
            .then((response) => {
                localStorage.setItem('messages_' + locale, JSON.stringify(response.data))
                setMessages(response.data)
                setLanguageLoading(false)
                showLanguageDialog(false)
                showToast && toast.current.show({
                    severity: 'info',
                    summary: response.data['topbar.language'],
                    detail: response.data['topbar.switched to'] + ' ' + langs[locale],
                    life: 5000,
                })
            })
    }

    const testMd5 = (test_md5) => {
        storedMessages = localStorage.getItem('messages_' + locale) ? JSON.parse(localStorage.getItem('messages_' + locale)) : { lang_md5: '' }
        if (storedMessages.lang_md5 !== test_md5) {
            getLang(default_language)
        } else {
            setMessages(storedMessages)
            setLanguageLoading(false)
            showLanguageDialog(false)
        }
    }

    useEffect(() => {
        testMd5(lang_md5)
    }, [])

    const switchLang = (lang) => {
        setLocale(lang)
        // setCookie('fauna_lang', lang, { path: '/', httpOnly: false })
        getLang(lang, true)
    }

    const value = {
        locale,
        messages,
        switchLang,
        testMd5,
    }
    // const ignoreDefaulMessageError = (error) => {
    //     if (typeof error.message === 'string' &&
    //         error.message.includes('[@formatjs/intl Error MISSING_TRANSLATION]')) {
    //
    //     }
    // }

    return (
        <Context.Provider value={value}>
            <Toast
                id={'lang_toast'}
                ref={toast} />
            <IntlProvider
                onError={() => {
                }}
                key={locale}
                locale={locale}
                messages={messages}
                defaultLocale={default_language}
            >{children}</IntlProvider>
        </Context.Provider>
    )
}

export { IntlProviderWrapper, Context as IntlContext }
