import axios from 'axios'

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.Laravel.csrfToken

window.axios.interceptors.response.use(response => response,
    err => {
        const share = window.location.pathname.includes('share')
        if (err && !share) {
            // console.log(err)
            switch (err.response.status) {
                case 401:
                case 419:
                    console.log('reloading: ' + err.response.status)
                    window.location.reload()
            }
        }

        return Promise.reject(err)
    })