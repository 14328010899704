import './bootstrap'
import { createRoot } from 'react-dom/client'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { LayoutProvider } from '@/Layouts/layout/context/layoutcontext'
import { IntlProviderWrapper } from '@/Layouts/layout/context/intlcontext'
import { createInertiaApp, router } from '@inertiajs/react'
import { PrimeReactProvider } from 'primereact/api'

if (window.location.host === 'app.faunaphotonics.com') {
    window.dataLayer = window.dataLayer || []

    function gtag() {
        dataLayer.push(arguments)
    }

    router.on('navigate', (event) => {
        gtag('event', 'page_view', {
            page_location: event.detail.page.url,
            // page_title: '[REPLACE WITH PAGE TITLE]',
        })
    })


}

createInertiaApp({
    title: (title) => `${title}`,// - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el)
        // MD5 of latest revision of translations
        // Initially logged in User
        const { lang_md5, user, impersonated_by } = props.initialPage.props
        root.render(
            <PrimeReactProvider>
                <LayoutProvider
                    user={user}
                    impersonated_by={impersonated_by}>
                    <IntlProviderWrapper lang_md5={lang_md5}>
                        <App {...props} />
                    </IntlProviderWrapper>
                </LayoutProvider>
            </PrimeReactProvider>,
        )
    },
    // progress: {
    //     color: '#4B5563',
    // },
})
